import React, { Component } from "react";
import Fade from "react-reveal";
import ParticlesBg from "particles-bg";
import hdImage from "../1.png";
class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const project = this.props.data.project;
    const github = this.props.data.github;
    const name = this.props.data.name;
    const description = this.props.data.description;

    return (
      
      <header id="home" >
        <ParticlesBg type="circle" bg={true} />
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>
        <div className="row banner" >

          <div className="banner-text">

            <Fade bottom>
              <h1 className="responsive-headline">Cedars Advisory</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>We help organizations across the private, public, and social sectors create the Change that Matters most to them.

From the C-suite to the front line, we partner with our clients to transform their organizations, embed technology into everything they do, and build enduring capabilities.</h3>
            </Fade>
            <hr />
          </div>
          
        </div>
      
        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
      
    );
  }
}

export default Header;
